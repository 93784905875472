.yllw_bnnr {
  flex-basis: 100%;
  flex-grow: 0;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #64a3ca;
  padding: 4px;
  margin-top: -26px;
  margin-bottom: -10px;
  color: #222831;
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .yllw_bnnr {
    display: none !important;
  }
  b {
    font-size: 13px;
  }
  span {
    font-weight: 600;
  }
}
.swap_tabs_main .amount_btn button {
  background: linear-gradient(90deg, #80f683 24.3%, #00d176) border-box !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 30px !important;
  text-align: center !important;
  color: #fff !important;
  border-radius: 5px !important;
}

.icon_all_sat {
  display: flex;
  align-items: center;
}
.icon_all_sat a {
  width: 44px !important;
  height: 44px !important;
  background: #f1f1f1;
  margin: 0 5px;
  border-radius: 50%;
}
.icon_all_sat img {
  width: 44px !important;
  height: 44px !important;
  padding: 10px;
}
.footer_link_text p {
  color: #6c757d;
}

@media screen and (max-width: 991px) {
  .token_box_main {
    padding: 0px !important;
  }
  .first_inpt_box input {
    margin-left: 0px;
  }
  .swap_inner {
    flex-direction: column !important;
    max-width: 100% !important;
  }
  .left_section {
    padding: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  .left_section_zkdefi {
    padding: 20px !important;
    max-width: 100% !important;
    width: 100% !important;
    border: 1px solid #dbdbdb !important;
    border-radius: 5px !important;
    background: transparent !important;
  }
  .swap_tabs_main {
    max-width: 100% !important;
    padding: 10px 20px !important;
  }
  .tokn_wdth_box {
    max-width: 100% !important;
  }
  .header_main {
    grid-template-columns: 60px 1fr 20px !important;
  }
  .lighr_dark_switch {
    margin: 0 10px 0 0 !important;
  }
  .eth_txt {
    margin: 0 !important;
  }
  .conct_wlt p {
    font-size: 14px !important;
  }
  .left_section {
    max-width: 100% !important;
    margin: 0 0 20px 0 !important;
  }
}
@media screen and (max-width: 575px) {
  .mainInputbx {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
  .arow_btn {
    top: 46%;
    left: 45%;
  }
  .first_inpt_box input {
    width: 100%;
    border-bottom: 1px solid #565a69;
  }
  .mobilefixer {
    width: 100%;
  }
  .open-currency-select-button {
    width:100%;
    border: 1px solid #565a69 !important;
    border-radius: 5px !important;
    margin-bottom: 10px;
  }
  .mainInputbx #swap-currency-input {
    align-items: center;
    margin-bottom: 10px !important;
  }
  .mainInputbx #swap-currency-output .first_inpt_box {
    align-items: center;
    margin-top: 10px;
  }
  .mainInputbx #swap-currency-output .first_inpt_box input {
    text-align: left;
  }
  .tabs_links a {
    padding: 13px 0 !important;
  }
  .header_main {
    grid-template-columns: 60px 1fr 20px !important;
    padding: 10px 10px 10px 10px !important;
  }
  .swap_tabs_main .amount_btn button {
    font-size: 14px !important;
  }
  .swap_tabs_main .amount_btn button div {
    font-size: 14px !important;
  }
  .inner_tx_box > div > div {
    font-size: 10px;
  }
  .inner_tx_box > div > div > div {
    font-size: 10px;
  }
  .small_txt {
    font-size: 10px !important;
  }
  .footer_containt_grid {
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }
  .conct_wlt p {
    font-size: 12px !important;
    margin: 0 !important;
  }
  .header_main {
    grid-template-columns: 60px 1fr 0px !important;
  }
  .main_pages {
    padding: 60px 10px 0 10px !important;
  }
  .botm_links {
    margin: 30px auto 0 auto !important;
  }
  .footer_containt h5 {
    margin: 8px 0 !important;
  }
}
.frmrg {
  margin-bottom: 8px;
}

.open-currency-select-button {
  border: 1px solid #565a69 !important;
  border-radius: 5px !important;
  margin-bottom: 10px;
}
